import React from "react";

function Footer() {
    return (
        <footer className="w-100 bg-dark p-3 text-white">
            <div className="container">
                <div className="text-center">
                    <small>Copyright © Destiny Capsules {new Date().getFullYear()}. All Rights Reserved. </small>
                    <div className="row justify-content-center">
                        <small>
                            Contact us at:
                            <a className="text-white ml-1" href="mailto: destinycapsules@gmail.com">
                                destinycapsules@gmail.com
                            </a>
                        </small>
                    </div>
                    <div className="row justify-content-center">
                        <small className="mt-2">
                            <a className="text-white"
                               href="https://destinycapsules.com/">
                                Visit Our Website</a>
                        </small>
                    </div>
                    <div className="row justify-content-center">
                        <small className="mt-2">Developed and maintained by <a className="text-white"
                                                              href="https://glitexsolutions.co.ke/">
                            Glitex Solutions</a>
                        </small>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;