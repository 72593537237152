import Home from "../pages/home";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import AudioBooks from "../pages/audio_books";
import VideoBooks from "../pages/video_books";
import BooksList from "../pages/books";
import OrdersList from "../pages/orders";
import OrderDetails from "../pages/orders/details/details";
import Logout from "../pages/auth/logout";
import Profile from "../pages/profile";
import Paypal from "../pages/cart/paypal";
import Blog from "../pages/blog";
import Academy from "../pages/academy";
import PasswordReset from "../pages/auth/password_reset";
import AboutUs from "../pages/about_us";
import BlogDetails from "../pages/blog/details";

const urls = {
    index: {
        path: '/',
        exact: true,
        component: Home,
    },
   /* all: {
        path: '/all',
        exact: true,
        component: Home,
    },*/
    login: {
        path: '/login',
        exact: true,
        component: Login,
    },
    logout: {
        path: '/logout',
        exact: true,
        component: Logout,
    },
    register: {
        path: '/register',
        exact: true,
        component: Register,
    },
    profile: {
        path: '/profile',
        exact: true,
        component: Profile,
    },
    books: {
        path: '/books',
        exact: true,
        component: BooksList,
    },
    audio_books: {
        path: '/podcasts',
        exact: true,
        component: AudioBooks,
    },
    video_books: {
        path: '/webinar',
        exact: true,
        component: VideoBooks,
    },
    orders: {
        path: '/orders',
        exact: true,
        component: OrdersList,
    },
    order_details: {
        path: '/orders/:id',
        exact: true,
        component: OrderDetails,
    },
    paypal: {
        path: '/paypal',
        exact: true,
        component: Paypal,
    },
    blog: {
        path: '/blog',
        exact: true,
        component: Blog,
    },
    blog_details: {
        path: '/blog/:id',
        exact: true,
        component: BlogDetails,
    },
    academy: {
        path: '/academy',
        exact: true,
        component: Academy,
    },
    about_us: {
        path: '/about_us',
        exact: true,
        component: AboutUs,
    },
    password_reset: {
        path: '/reset-password/:token',
        exact: true,
        component: PasswordReset
    }
}

export default urls;