import urls from "./urls";

export const routes = [
    urls.index,
    urls.login,
    urls.register,
    urls.profile,
    urls.books,
    urls.audio_books,
    urls.video_books,
    urls.order_details,
    urls.orders,
    urls.logout,
    urls.paypal,
    urls.blog,
    urls.blog_details,
    urls.academy,
    urls.password_reset,
    urls.about_us,
    //urls.all
];