import React from 'react';
import homeBg from '../../assets/img/home_bg.jpg';
import whyUs from '../../assets/img/why_us.png';
import coreValues from '../../assets/img/core_values.png';
import mission from '../../assets/img/mission.png';
import vision from '../../assets/img/vision.png';
import userIcon from '../../assets/img/login-bg.jpg';
import offers from '../../assets/img/offer.png';
import userCard from '../../assets/img/user_card.jpg';
import bookBg from '../../assets/img/book_bg.png';

function AboutUs() {
    return (
        <div>
            <div id="homeCarousel" className="carousel slide" data-ride="carousel">
                <div style={{height: "80vh"}} className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="overlay"/>
                        <img src={userCard} style={{height: "80vh", objectFit: "cover"}} className="d-block w-100"
                             alt="..."/>
                        <div className="carousel-caption d-flex h-100 align-items-end justify-content-center">
                            <h1>The Founder.</h1>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="overlay"/>
                        <img src={homeBg} style={{height: "80vh", objectFit: "cover"}} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-flex h-100 align-items-end justify-content-center">
                            <h1>Destiny Capsules.</h1>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="overlay"/>
                        <img src={bookBg} style={{height: "80vh", objectFit: "cover"}} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-flex h-100 align-items-end justify-content-center">
                            <h1>Destiny Capsules.</h1>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#homeCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#homeCarousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            <div className="container my-5">
                <Container>
                    <div className="col-lg-6">
                        <h3 className="font-weight-bold">
                            OUR MISSION
                        </h3>
                        <p>
                            Destiny Capsules is a platform that exists to empower lives to fulfil their
                            God-given mandate in life by offering insightful and innovative
                            ideas whose application will lead to long lasting impact irrespective of one’s career or
                            calling.
                        </p>
                    </div>
                    <div className="col-lg-6">
                        <Image alt="about_image" src={mission}/>
                    </div>
                </Container>
                <Container>
                    <div className="col-lg-6">
                        <Image alt="about_image" src={vision}/>
                    </div>
                    <div className="col-lg-6">
                        <h3 className="font-weight-bold">
                            OUR VISION
                        </h3>
                        <p>
                            Shaping Destinies through Insights and Innovation for Maximum Impact
                        </p>
                    </div>
                </Container>
                <Container className="row mx-auto justify-content-center align-items-center">
                    <div className="col-lg-5">
                        <h3 className="font-weight-bold">
                            WHY DESTINY CAPSULES?
                        </h3>
                        <ul>
                            <li>Affordable</li>
                            <li>Quality</li>
                            <li>Relevant</li>
                            <li>Life Changing</li>
                        </ul>
                    </div>
                    <div className="col-lg-5">
                        <Image alt="about_image" src={whyUs}/>
                    </div>
                </Container>
                <Container className="row mx-auto justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <Image alt="about_image" src={offers}/>
                    </div>
                    <div className="col-lg-6">
                        <p>We also offer Consultancies, Linkages, Workshops, Webinars and Mentorship in the following
                            areas:
                        </p>
                        <ul>
                            <li>Personal Branding</li>
                            <li>Leadership and Governance</li>
                            <li>Christian Ministry</li>
                            <li> Curriculum and Instruction</li>
                            <li> Online Education</li>
                            <li> Counseling Psychology</li>
                            <li> Board Efficiency and Effectiveness</li>
                            <li> Critical Thinking Skills</li>
                        </ul>
                    </div>
                </Container>
                <Container className="row mx-auto justify-content-center align-items-center">
                    <div className="col-lg-5">
                        <h3 className="font-weight-bold">
                            OUR CORE VALUES
                        </h3>
                        <ul>
                            <li>Excellence.</li>
                            <li>Relevance.</li>
                            <li>Innovation.</li>
                            <li>Integrity.</li>
                            <li>Character</li>
                        </ul>
                    </div>
                    <div className="col-lg-5">
                        <Image alt="about_image" src={coreValues}/>
                    </div>
                </Container>
                <Container className="row mx-auto justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <h3 className="text-center mb-5">
                            ABOUT THE EXECUTIVE DIRECTOR/FOUNDER
                        </h3>
                        <Image alt="about_image" src={userIcon}/>
                    </div>
                    <div className="col-lg-6">
                        <p>
                            Dr. Bernard Kimani is the executive director, founder, and CEO of Destiny Capsules. He is
                            passionate about the transformation of human leadership in a holistic way. He has been
                            involved at various senior management levels for private and public organization. Dr. Kimani
                            is also a board member in the Public Service Board of the County Government of Kiambu. He
                            also lectures on parttime basis at Riara University and Africa International University.
                            <br/>
                            <br/>
                            Additionally, Dr. Kimani has been extensively involved in Christian Ministry as a pastor,
                            Overseer/Bishop of the PEFA Church. Moreover, he has been involved in the training of
                            leaders for effective Christian Ministry at Nairobi Pentecostal Bible College.
                            <br/>
                            <br/>
                            Dr. Kimani is a sought-after conference speaker locally and internationally. He is an
                            acclaimed author of several titles among them Uncover Your Greatness and Success Boosters.
                            He holds a BA, MA, and PhD degrees from recognized Universities in Kenya.
                            <br/>
                            <br/>
                            He works alongside his wife Saph Wanjiru, who is a Counseling Psychologist.
                        </p>
                    </div>
                </Container>
            </div>
        </div>
    );
}


function Image({alt, src}) {
    return (
        <img style={{width: "100%", objectFit: "contain"}} alt={alt} src={src}/>
    )
}

function Container({children}) {
    return (
        <div className="row mx-auto justify-content-center align-items-center my-5">
            {children}
        </div>
    )
}

export default AboutUs;