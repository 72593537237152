import React from "react";
import {numberWithCommas} from "../../../helpers/helper_functions";
import endpoints from "../../../routes/endpoints";

class OrderItem extends React.Component {

    handleDownload = () => {
        const {item, orderId} = this.props;
        window.open(`/api/${endpoints.download_book}?book=${item.book.id}&order=${orderId}`)
    }

    render() {
        const {item} = this.props;
        return (
            <div className="card shadow-sm my-3">
                <div className="card-body">
                    <div className="col-12">
                        <h3>{item.book.title || "-"}</h3>
                        <div className="d-flex justify-content-between">
                            <p> KES {numberWithCommas(item.book.price)}</p>
                            <p> {item.quantity} {item.quantity > 1 ? "items" : 'item'}</p>
                        </div>
                        <hr className="mb-3"/>
                        <div className="d-flex justify-content-between">
                            <h4>
                                Total: KES {numberWithCommas(item.book.price * item.quantity)}
                            </h4>
                            <button onClick={this.handleDownload} className="btn btn-success">
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderItem;