export const DOMAIN = "/api";

export const USER_OBJECT = "user_object";

export const JWT_TOKEN = "jwt_token";

//TODO: Remove this
export const REFRESH_TOKEN = "refresh_token";

export const GET_REQUEST = "get_request";
export const POST_REQUEST = "post_request";
export const PUT_REQUEST = "put_request";
export const PATCH_REQUEST = "patch_request";
export const DELETE_REQUEST = "delete_request";
export const SUBDOMAIN_NAME = "subdomain_name";
export const ORGANIZATION_DATA = "organization_data";

export const RATES = "rates";
